<template>
  <div class="analysis-tfoms__table analysis-tfoms__base-table">
    <base-table-full
      :dataSource="data"
      :headers="type === 1 ? headers1 : headers2"
      hide-default-footer
      notShowSelect
      nested
      not-filter
      @click:row="click"
      :item-class="
        (item) => (value && item.Id === value.Id ? 'table-item--active' : '')
      "
      :sort-by="['Value']"
      :sort-desc="[false]"
      :exclude-settings-actions="['table', 'group']"
    >
    </base-table-full>
  </div>
</template>
<script>
import moment from "moment";

export default {
  components: {
    BaseTableFull: () => import("@/components/base/BaseTableFull"),
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
      default: 1,
    },
    MonthsCount: {
      type: Number,
      default: parseInt(moment().format("MM")),
    },
    refs: { type: Array, defaulta: null },
  },
  data() {
    return {
      headers1: [
        {
          value: "Indicator",
          text: "Показатель",
          dataType: "object",
          displayText: (e) => e.Name,
        },
        {
          value: "Value",
          text: "Оценка",
          dataType: "numberBar",
          options: { refs: this.refs },
          cellClass: "px-0",
        },
        {
          value: "Date",
          text: "Дата оценки",
          dataType: "Date",
          displayText: (e) => e && moment(e, "DD.MM.YYYY").format("MMMM YYYY"),
        },
      ],
      headers2: [
        {
          value: "Organization",
          text: "Медицинская организация",
          dataType: "object",
          displayText: (e) => e.Name,
        },
        {
          value: "Value",
          text: "Оценка",
          dataType: "numberBar",
          options: { refs: this.refs },
          cellClass: "px-0",
        },
        {
          value: "Date",
          text: "Дата оценки",
          dataType: "Date",
          displayText: (e) => e && moment(e, "DD.MM.YYYY").format("MMMM YYYY"),
        },
      ],
    };
  },
  methods: {
    click(val) {
      this.$emit("input", val?.Id === this.value?.Id ? null : val);
    },
  },
};
</script>
